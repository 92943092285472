import React from 'react';
import SEO from '../components/SEO';
import Layout from '../components/Layout';

const ImpressumPage = () => {
  return (
    <Layout>
      <SEO title="Impressum | React Freelancer Switzerland" />
      <main>
        <div className="container text-left pt-5">
          <h1 className="text-dark mb-3">Impressum</h1>
          <p>
            Florian Gyger Software
            <br />
            Bachmattstrasse 15
            <br />
            CH-5210 Windisch
            <br />
            <a
              href="mailto:info@floriangyger.ch"
              title="Write an email to Florian Gyger"
            >
              info@floriangyger.ch
            </a>
            <br />
          </p>
          <p>UID: CHE-419.161.481</p>
          <p>
            Main website:{' '}
            <a
              href="https://floriangyger.ch"
              target="_blank"
              title="Website of Florian Gyger Software"
            >
              www.floriangyger.ch
            </a>
          </p>
        </div>
        <div className="overlay"></div>
      </main>
    </Layout>
  );
};

export default ImpressumPage;
